import React from "react";
import { AppConfig } from "../../config";
import { Connect, Registry, Check } from "./components";

const NFTRegistry = () => {
  return <Registry />;
};

class NFTMint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ethers: {},
    };

    this.methods = {
      updEthers: (data) => this.setState({ ethers: { ...data } }),
      totalSupply: (amount) => this.setState({ totalSupply: amount }),
      maxSupply: (amount) => this.setState({ maxSupply: amount }),
    };
  }

  render() {
    return (
      <AppConfig.Provider value={{ ...this.state, methods: this.methods }}>
        <Connect />
      </AppConfig.Provider>
    );
  }
};

export { NFTRegistry, NFTMint, Check };
