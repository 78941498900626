import { Check } from "../nft";
import "./index.css";

function DownloadMusic() {
  return (
    <div className="rarity_container">
      <div className="rarity_heading">
        <h1>
          Download your exclusive <br /> Gratitude Track by MC FLIPSIDE
        </h1>

        <Check />
      </div>
    </div>
  );
}

export default DownloadMusic;
