import Artist from "../components/artist";
import Cover from "../components/mint_cover";
import Footer from "../components/footer";
import Join from "../components/join";
import JoinDiscord from "../components/join_discord";
import Navbar from "../components/navbar";
import Team from "../components/team";
import Faq from "../components/faq"
import Roadmap from "../components/roadmap";
import SwiperModal from "../components/maestros/modal";
import React, { useRef, useEffect, useState } from "react";
import Collection from "../components/collection";

const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom,
    };
};

function Home() {
    const [visibleSection, setVisibleSection] = useState();
    const ArtistRef = useRef(null);
    const JoinRef = useRef(null);
    const TeamRef = useRef(null);
    const FaqRef = useRef(null);
    const RoadmapRef = useRef(null);
    const RarityRef = useRef(null);

    const sectionRefs = [
        { section: "art", ref: ArtistRef },
        { section: "joinn", ref: JoinRef },
        { section: "teamm", ref: TeamRef },
        { section: "faqq", ref: FaqRef },
        { section: "road_map", ref: RoadmapRef },
        { section: "rarity", ref: RarityRef }
    ];

    useEffect(() => {
        const handleScroll = () => {
            // const { height: headerHeight } = getDimensions(headerRef.current);
            const scrollPosition = window.scrollY + 100;
            const selected = sectionRefs.find(({ section, ref }) => {
                const ele = ref.current;
                if (ele) {
                    const { offsetBottom, offsetTop } = getDimensions(ele);
                    return scrollPosition > offsetTop && scrollPosition < offsetBottom;
                }
            });

            if (selected && selected.section !== visibleSection) {
                setVisibleSection(selected.section);
            } else if (!selected && visibleSection) {
                setVisibleSection(undefined);
            }
        };

        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [visibleSection]);

    return (
        <div className='App'>
            <div className="joinn" ref={JoinRef}>
                <Navbar activeSection={visibleSection} />
                <Cover />
                <Join />
            </div>
            <div className='art' ref={ArtistRef}>
                <Artist />
                <SwiperModal />
            </div>
            <div id='rarity' ref={RarityRef}>
                <Collection />
            </div>
            <div className="road_map" ref={RoadmapRef}>
                <Roadmap />
            </div>
            <div className="teamm" ref={TeamRef}>
                <Team />
            </div>
            <div className="faqq" ref={FaqRef}>
                <Faq />
                <JoinDiscord />
                <Footer />
            </div>
        </div >
    );
}

export default Home;