import { Route } from "react-router-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "../pages/index";
import Music from "../pages/music";
import Private from "../pages/private";
import Soldout from "../pages/soldout";

function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route path={"/music"} component={Music} />
        <Route path={"/private"} component={Private} />
        <Route path={"/"} component={Home} />
        {/* <Route path={"/"} component={Soldout} /> */}
      </Switch>
    </Router>
  );
}

export default AppRouter;
