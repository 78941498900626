import { useState, useEffect, useRef } from "react";

import Slider from "react-slick";
import p1 from '../../assets/images/maestros/Do.jpg'
import p2 from '../../assets/images/maestros/Do-1.jpg'
import p3 from '../../assets/images/maestros/Fa.jpg'
import p4 from '../../assets/images/maestros/La.jpg'
import p5 from '../../assets/images/maestros/Mi.jpg'
import p6 from '../../assets/images/maestros/Re.jpg'
import p8 from '../../assets/images/maestros/So.jpg'
import p9 from '../../assets/images/maestros/Si.jpg'
import a2 from '../../assets/images/2.png'
import a1 from '../../assets/images/1.png'

import './modal.css'

export default function SyncSlider({ startIndex }) {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    function scroll(e) {
        if (slider1 === null)
            return 0;

        e.wheelDelta > 0 ? (
            slider1.current.slickNext()
        ) : (
            slider1.current.slickPrev()
        );

    };

    function SamplePrevArrow() {
        slider1.current.slickPrev()
    }
    function SampleNextArrow() {
        slider1.current.slickNext()
    }
    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);

        window.addEventListener("wheel", scroll, true);

        return () => {
            window.removeEventListener("wheel", scroll, true);
        };
    }, []);

    return (
        <div>
            <Slider
                arrows={false}
                vertical={true}
                slidesToShow={1}
                // infinite={false}
                initialSlide={startIndex}

                verticalSwiping={true}
                centerMode={true}
                swipeToSlide={true}
                focusOnSelect={true}
                className="first_slider"
                asNavFor={nav2} ref={slider1}
            >
                <div className="slide_container">
                    <img src={p1} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p2} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p3} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p4} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p5} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p6} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p8} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p9} alt='' />
                </div>
            </Slider>
            <Slider
                className="thumbnail_slider"
                asNavFor={nav1}
                ref={slider2}
                initialSlide={startIndex}
                slidesToShow={5}
                // swipeToSlide={true}
                centerMode={true}
                arrows={false}
                vertical={true}
                // verticalSwiping={true}
                focusOnSelect={true}
            >
                <div className="slide_container">
                    <img src={p1} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p2} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p3} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p4} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p5} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p6} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p8} alt='' />
                </div>
                <div className="slide_container">
                    <img src={p9} alt='' />
                </div>
            </Slider>
            <div className="arrow_container">
                <span onClick={SamplePrevArrow}>
                    <img src={a2} alt='' />

                </span>
                <span onClick={SampleNextArrow}>
                    <img src={a1} alt='' />
                </span>
            </div>
        </div >
    );
}
