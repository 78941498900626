import Countdown from "react-countdown";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { NFTMint } from "../nft";

import "./index.css";
import { Redirect } from "react-router-dom";

const fade_right = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;

const Completionist = () => <Redirect to="/" />;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <div className="contdown_wrapper">
        <span className="countdown_text">
          {days}
          <br /> DAYS
        </span>
        <span className="semi_colon">:</span>
        <span className="countdown_text">
          {hours} <br /> HOURS
        </span>
        <span className="semi_colon">:</span>
        <span className="countdown_text">
          {minutes} <br /> MINUTES
        </span>
        <span className="semi_colon ">:</span>
        <span className="countdown_text ">
          {seconds} <br /> SECONDS
        </span>
      </div>
    );
  }
};

function Cover() {
  if (!window.localStorage.getItem("countdown")) {
    localStorage.setItem("countdown", Date.now() + 1800000);
  }

  const date = parseInt(window.localStorage.getItem("countdown"));

  return (
    <div className="rarity_container">
      <Reveal triggerOnce={true} keyframes={fade_right}>
        <div className="cover_content">
          <div className="rarity_heading">
            <h1>
              WAIT! <br /> Beat The Clock: You're Now On the Whitelist,
But You Can Get Your Bear Right Away At A 33% Discount
(0.08 instead of 0.12 when it's officially live)
Within The Next 30 Minutes
            </h1>
          </div>

          <div className="d-flex flex-column align-items-center">
            <Countdown date={date} renderer={renderer} />

            <div className="cover_button">
              <NFTMint />
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  );
}

export default Cover;
