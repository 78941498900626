import './App.css';
import AppRouter from './config/router';

function App() {
  return (
   <AppRouter/>
  );
}

export default App;
