import React from "react";
import { ethers } from "ethers";
import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
import { Alert, Mint } from "..";
import {
  AppConfig,
  config,
  Helper,
  _web3Modal,
  abi,
  Request,
} from "../../../../config";

class Connect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: [],
      connected: false,
      sold: false,
      in_whitelist: false,
      submit: false,
    };
  }

  async handleConnect() {
    const provider = await _web3Modal.connect();
    const { methods } = this.context;

    this.provider = new ethers.providers.Web3Provider(provider);
    const network_id = await this.provider.send("net_version");

    this.setState({ submit: true });

    // NETWORK ID
    if (parseInt(network_id) !== parseInt(config.network)) {
      this.setState({
        alerts: [
          {
            type: "error",
            message: `Change network to ${Helper.networkName(config.network)}.`,
          },
        ],
      });

      return false;
    }

    try {
      const signer = this.provider.getSigner();
      const address = await signer.getAddress();

      const contract = new ethers.Contract(
        config.contract_address,
        abi,
        signer
      );

      const paused = await contract.paused();
      const maxSupply = await contract.maxSupply();
      const totalSupply = await contract.totalSupply();
      const balanceOf = await contract.balanceOf(address);

      // PAUSED
      if (paused) {
        this.setState({
          alerts: [
            {
              type: "error",
              message: config.messages.paused_contract,
            },
          ],
          submit: false,
        });

        return false;
      }

      // SOLD
      if (maxSupply.eq(totalSupply)) {
        this.setState({
          alerts: [
            {
              type: "error",
              message: config.messages.sold_out,
            },
          ],
          sold: true,
          submit: false,
        });

        return false;
      }

      // WHITELIST
      const isWhitelisted = await contract.whitelistedSale();
      if (isWhitelisted) {
        const { data } = await Request.in_whitelist({ address });

        if (data.result === false) {
          this.setState({
            alerts: [
              {
                type: "error",
                message: config.messages.not_in_whitelist,
              },
            ],
            in_whitelist: false,
            submit: false,
            connected: true,
          });

          return false;
        }
      }

      const cost = await contract.cost();
      const whitelistCost = await contract.whitelistCost();
      let maxMintAmount = 0;

      if (isWhitelisted) {
        const presaleLimit = await contract.presaleLimit();
        maxMintAmount = presaleLimit.toNumber() - balanceOf.toNumber();
      } else {
        const publicLimit = await contract.publicLimit();
        maxMintAmount = publicLimit.toNumber() - balanceOf.toNumber(); 
      }

      if (maxMintAmount < 1) {
        this.setState({
          alerts: [
            {
              type: "error",
              message: config.messages.limit_token,
            },
          ],
          submit: false,
        });
      }

      maxMintAmount = maxMintAmount > 0 ? maxMintAmount : 0;

      methods.updEthers({
        contract,
        signer,
        address,
        cost,
        whitelistCost,
        isWhitelisted,
        maxMintAmount: maxMintAmount > 10 ? 10 : maxMintAmount,
      });

      this.setState({ connected: true, in_whitelist: true, submit: false });
    } catch (err) {
      this.setState({
        alerts: [{ type: "error", message: err.message }],
        submit: false,
      });
    }
  }

  render() {
    const { alerts, connected, sold, in_whitelist, submit } = this.state;
    const fade_right = keyframes`
    from {
        opacity: 0;
        -webkit-transform: translate3d(50px, 0, 0);
        transform: translate3d(50px, 0, 0);
      }

      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    `;

    // SOLD
    if (sold) {
      return (
        <div className="mint_container">
          <div className="message_box">
            <p className="alert-p">{config.messages.sold_out}</p>
          </div>
          <a
            className="connect_btn text-center text-decoration-none"
            href={config.messages.opensea_link}
            target="_blank"
            rel="noreferrer"
          >
            VISIT OPENSEA
          </a>
        </div>
      );
    }

    return (
      <>
        {alerts.length > 0 &&
          alerts.map((alert, index) => <Alert key={index} {...alert} />)}

        {!connected && alerts.length === 0 && (
          <button
            className="to_open_mintbox_button"
            onClick={this.handleConnect.bind(this)}
            disabled={submit}
          >
            {config.messages.mint_button_text}
          </button>
        )}

        {connected && alerts.length === 0 && (
          <Reveal triggerOnce={true} keyframes={fade_right}>
            <Mint />
          </Reveal>
        )}

        {connected && !in_whitelist && (
          <a
            className="to_open_mintbox_button"
            href={config.messages.opensea_link}
            target="_blank"
            rel="noreferrer"
          >
            BUY ON OPENSEA
          </a>
        )}
      </>
    );
  }
}

Connect.contextType = AppConfig;

export default Connect;
