import React from "react";
import "./index.scss";

class NFTPay extends React.Component {
  modal = null;

  componentDidMount() {
    this.modal = document.querySelector(".nftpay_modal");

    if (!this.modal) {
      this.modal = document.createElement("div");
      this.modal.classList.add("nftpay_modal");
      this.modal.setAttribute("data-show", false);

      this.modal.innerHTML = `
        <div class="nftpay_modal__content">
            <button data-close></button>
            <iframe height="550" title="NFTPay" style="border: none" src="https://payments.nftpay.xyz/iframe/iframe_pay/be8bf763-11de-4565-bf10-c6de071e2d9d"></iframe>
        </div>`;
      document.body.appendChild(this.modal);

      this.modal
        .querySelector("[data-close]")
        .addEventListener("click", this.toggleModal.bind(this));
    }
  }

  toggleModal() {
    this.modal.setAttribute("data-show", !(this.modal.dataset.show === "true"));
    document.body.classList.toggle("overflow-hidden");
  }

  render() {
    return (
      <div>
        <button
          className="text-uppercase to_open_mintbox_button"
          onClick={this.toggleModal.bind(this)}
        >
          Pay With Credit Card
        </button>
      </div>
    );
  }
}

export default NFTPay;
