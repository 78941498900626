import React from "react";

import { ethers, utils } from "ethers";
import { config, Helper, Request, _web3Modal } from "../../../../config";
import { Alert } from "..";
import { Redirect } from "react-router-dom";

class Registry extends React.Component {
  constructor() {
    super();

    this.state = {
      alert: null,
      connected: false,
      has_balance: utils.parseEther(config.has_balance),
    };
  }

  async handleConnect() {
    this.setState({ alert: null });
    const provider = await _web3Modal.connect();

    this.provider = new ethers.providers.Web3Provider(provider);
    const network_id = await this.provider.send("net_version");

    if (parseInt(network_id) !== parseInt(config.network)) {
      this.setState({
        alert: {
          type: "danger",
          message: `Change network to ${Helper.networkName(config.network)}.`,
        },
      });

      return false;
    }

    try {
      const signer = this.provider.getSigner();
      const address = await signer.getAddress();

      // Check account balance
      if (config.check_balance) {
        const { has_balance } = this.state;
        const balance = await signer.getBalance();

        if (balance.lt(has_balance)) {
          this.setState({
            alert: {
              type: "danger",
              message: `Sorry, your account balance is lower than the required minimum. Please add funds and retry. You need at least ${config.has_balance} ETH to register.`,
            },
          });

          return false;
        }
      }

      const { data } = await Request.registry({ address });

      if (data.status === "success") {
        this.setState({
          connected: true,
          alert: {
            type: "success",
            message: config.messages.success_registry,
          },
        });

        setTimeout(() => window.location.replace("private"), 1000);
      } else {
        this.setState({
          connected: false,
          alert: {
            type: "danger",
            message: config.messages.success_registry,
          },
        });
      }
    } catch (err) {
      this.setState({ alert: { type: "danger", message: err.message } });
    }
  }

  render() {
    const { alert, connected } = this.state;

    return (
      <>
        {alert !== null && <Alert {...alert} />}

        {!connected && (
          <button
            className="to_open_mintbox_button text-uppercase"
            onClick={this.handleConnect.bind(this)}
          >
            Get Whitelisted
          </button>
        )}
      </>
    );
  }
}

export default Registry;
