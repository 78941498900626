import React from "react";

import { ethers, utils } from "ethers";
import { config, Helper, Request, _web3Modal } from "../../../../config";
import { Alert } from "..";

class Check extends React.Component {
  constructor() {
    super();

    this.state = {
      alert: null,
      connected: false,
      has_balance: utils.parseEther(config.has_balance),
    };
  }

  async handleConnect() {
    this.setState({ alert: null });

    const provider = await _web3Modal.connect();

    this.provider = new ethers.providers.Web3Provider(provider);
    const network_id = await this.provider.send("net_version");

    if (parseInt(network_id) !== parseInt(config.network)) {
      this.setState({
        alert: {
          type: "danger",
          message: `Change network to ${Helper.networkName(config.network)}.`,
        },
      });

      return false;
    }

    try {
      const signer = this.provider.getSigner();
      const address = await signer.getAddress();

      const { data } = await Request.check_nft({ address });

      if (data.hasOwnProperty("token")) {
        return this.setState({
          connected: true,
          alert: {
            type: "success",
            message: `Your link for <a target="_blank" href="${config.ajax_request_url}nft/download/${data.token}">download track</a>`,
          },
        });
      }

      return this.setState({
        connected: true,
        alert: {
          type: "success",
          message: data.message,
        },
      });
    } catch (err) {
      this.setState({ alert: { type: "danger", message: err.message } });
    }
  }

  render() {
    const { alert, connected } = this.state;

    return (
      <>
        {alert !== null && <Alert {...alert} />}

        {!connected && (
          <button
            className="to_open_mintbox_button"
            onClick={this.handleConnect.bind(this)}
          >
            DOWNLOAD
          </button>
        )}
      </>
    );
  }
}

export default Check;
