import Cover from "../components/countdown";

function Private() {
  return (
    <div className="App">
      <div className="">
        <Cover />
      </div>
    </div>
  );
}

export default Private;
