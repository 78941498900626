import "./index.css";
import HEADING from "../../assets/images/heading.png";
import BEAR from "../../assets/images/red-cap.png";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import MintBox from "./mint_box";
import NFTPay from "../nftpay";
import { NFTRegistry } from "../nft";
import { config } from "../../config";

const pulse = keyframes`
from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;
const fade_right = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;

function Cover() {
  return (
    <div className="mint_cover_container">
      <div className="mint_cover_bear_container">
        <Reveal triggerOnce={true} keyframes={pulse}>
          <img src={BEAR} alt="" />
        </Reveal>
      </div>

      <div className="mint_cover_content_container">
        <Reveal triggerOnce={true} keyframes={fade_right}>
          <div
            data-gdlr-animation-duration="600ms"
            data-gdlr-animation-offset="0.8"
            className="m_bear_heading_container"
          >
            <img src={HEADING} alt="" />
          </div>
        </Reveal>

        <p
          style={{
            margin: "10px 0px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
          className="text-center"
        >
          Choose your payment method below and start minting your Beats Bears (0.06 ETH each)
        </p>

        {config.raffle ? (
          <NFTRegistry />
        ) : (
          <>
            <MintBox />
            <p
              style={{
                margin: "10px 0px",
                fontWeight: "bold",
                fontSize: "28px",
              }}
              className="text-center"
            >
              OR
            </p>
            <NFTPay />
          </>
        )}
      </div>
    </div>
  );
}

export default Cover;
